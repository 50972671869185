import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _Combination2 from "./Combination";
import _UI2 from "./UI";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {};
exports["default"] = void 0;

var _Combination = _interopRequireDefault(_Combination2);

var _UI = _UI2;
Object.keys(_UI).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _UI[key];
    }
  });
});
var _default = _Combination["default"];
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule,
      FocusLockUI = exports.FocusLockUI,
      AutoFocusInside = exports.AutoFocusInside,
      MoveFocusInside = exports.MoveFocusInside,
      useFocusInside = exports.useFocusInside,
      FreeFocusInside = exports.FreeFocusInside,
      InFocusGuard = exports.InFocusGuard;